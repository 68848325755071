/** @jsx jsx */
import { jsx } from 'theme-ui'
import '../styles/layout.css'

const Layout = ({ children }) => (
  <div>
    <div>{children}</div>
    <footer
      sx={{
        borderTop: 'gray2',
      }}
    >
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '960px',
          py: [8, 16],
          px: [6, 8],
          my: 0,
          mx: 'auto',
        }}
      >
        <span
          sx={{
            fontSize: 'sm',
            color: 'black',
            opacity: 0.8,
          }}
        >
          &copy; {new Date().getFullYear()} Eunjae Lee
        </span>
      </div>
    </footer>
  </div>
)

export default Layout
