import { isFuture } from 'date-fns'

export function cn (...args) {
  return args.filter(Boolean).join(' ')
}

export function filterPostNodesFromEdges (postEdges) {
  return postEdges
    ? mapEdgesToNodes(postEdges)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []
}

export function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs ({ slug }) {
  return (slug || {}).current
}

export function filterOutDocsPublishedInTheFuture ({ publishedAt }) {
  return publishedAt && !isFuture(publishedAt)
}

export function getBlogUrl (slug, seriesSlug) {
  return `/${seriesSlug || 'post'}/${slug.current || slug}`
}

export function buildImageObj (source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function toPlainText (blocks) {
  if (!blocks) {
    return ''
  }
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      return block.children.map(child => child.text).join('')
    })
    .join('\n\n')
}
